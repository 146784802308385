const translations = {
  en: {
    language: 'English',
    menu: {
      pageTitle: 'Your future awaits : Embrace the journey of finding your dream job',
      liveJobs:"live jobs",
      wehave:"We have",
      pageDescr:" Find and prepare for your dream jobs,pass tests and assess your skills, unlock new opportunities, showcase your strengths, create well-crafted resumes",
      livejobs2:"We have plenty of live Jobs",
      RequestDemo:"Request Demo",
      BrowserFeatures:"Browser Features",
      BrowserFeaturesDesc1:"Experience our website and more to make your browsing faster, safer and more tailored to your .",
      BrowserFeaturesDesc2:"These features collectively enhance browsing efficiency, personalization and security ",
      NewRandomJobs:"New & Random Jobs",
      NewRandomJobsDesc1:"Unlock your potential : the right job is just a click away",
      NewRandomJobsDesc2:"Effortless connect with the best freelancers for your needs ",
      forCandidate:"For Candidate",
      forRecruiter:"For Recruiter",
      candidatesGuide:"How It Works for Candidates",
      candidatesGuideDesc:"Get started with your application",
      candidatestep1Title:"Create an account ",
      candidatestep1:"Sign up by providing your basic details and creating a profile. This helps us underestand your skills, experience and career goals",
      candidatestep2Title:"Complete your profile",
      candidatestep2:"Fill out your profile with information about your qualifications, work history and preferences. This ensures we match you with relevant opportunities",
      candidatestep3Title:"Browse opportunities",
      candidatestep3:"Explore available job listing or projects that align with your skills and interests",
      candidatestep4Title:"Submit application",
      candidatestep4:"Apply to the positions or projects that interst you. Tailor your application to highlight how your background fits the role",
      candidatestep5Title:"Get matched",
      candidatestep5:"Our system reviews your profile and applications, then matches you with the most suitable opportunities",
      candidatestep6Title:"Play coding games",
      candidatestep6:"Sharpen your coding skills through fun games.",

      recruiterGuide:"How It Works for Recruiters",
      recruiterGuideDesc:"How the process works for recruiters.",
      recruitertep1Title:"Create an account ",
      recruitertep1:"Sign up by providing your basic details and creating a profile. This helps us underestand your needs, efficiently and effectively match candidates with job opportunities",
      recruitertep2Title:"Create detailed job description",
      recruitertep2:"Ensure job descriptions are precise and include key responsabilities, required qualifications and desired skills",
      recruitertep3Title:"Establish evaluation criteria",
      recruitertep3:"Develop clear criteria for assessing candidates (technical, psycho-technicaland personality) to ensure consistency and fairness in the selection process",
      recruitertep4Title:"Streamline application process",
      recruitertep4:"Monitor and improve employee performance using data- driven insights ",
      recruitertep5Title:"Enhance candidate experience",
      recruitertep5:" Ind and hire talented candidates for your projects ",
      
      checkOur:"Check our",
      latestJob:"latest job openings now",
      descCheck:"Share your project with us, and we will swiftly connect you with the perfect freelancers",
      HappyCandidates:"Happy Candidates",
      HappyCandidatesDesc:"Tell us about your project, and we will promptly pair you with the ideal freelancers",
      FastTrack:"Fast track your success and quick career tips",
      FastTrackDesc:"Submit your project details, and we will quickly find the right freelancers for you",
      hirecueFooter:"It is a well established fact that a reader's impression is greatly influenced by the layout of a page",
      Pricing:" Pricing",
      AboutUs :"About Us ",
      SignIn:"Sign In",
      SignUp:"Sign Up",
      email:"Email",
      phone:"Phone",
      type:"Type",
      Customdemo:"Custom demo",
      Generaldemo:"General demo",
      description:"Description",
      close:"Close",
      submit:"Submit",
    }
  },
  fr: {
  language: 'Français',
  menu: {
    pageTitle: "Votre avenir vous attend : Découvrez l'aventure de la recherche du travail de vos rêves",
    liveJobs : " emplois en direct ",
      wehave : " Nous avons ",
      pageDescr : " Trouvez et préparez-vous à l'emploi de vos rêves, passez des tests et évaluez vos compétences, débloquez de nouvelles opportunités, mettez en valeur vos points forts, créez des CV bien rédigés ",
      livejobs2 : " Nous avons de nombreux emplois en direct ",
      RequestDemo : " Demander une démonstration ",
      BrowserFeatures : "Fonctionnalités du navigateur ",
      BrowserFeaturesDesc1 : "Découvrez notre site Web et plus encore pour rendre votre navigation plus rapide, plus sûre et mieux adaptée à vos besoins ",
      BrowserFeaturesDesc2 : "Ces fonctionnalités améliorent collectivement l'efficacité, la personnalisation et la sécurité de la navigation ",
      NewRandomJobs : "Emplois nouveaux et aléatoires ",
      NewRandomJobsDesc1 : "Libérez votre potentiel : le bon emploi est à portée de clic ",
      NewRandomJobsDesc2 : "Connectez-vous sans effort avec les meilleurs freelances pour vos besoins ",
      forCandidate : "Pour le candidat ",
      forRecruiter : "Pour le recruteur ",
      candidatesGuide : "Comment ça marche pour les candidats ",
      candidatesGuideDesc : "Commencez votre candidature ",
      candidatestep1Title : "Créez un compte ",
      candidatestep1 : "Inscrivez-vous en fournissant vos informations de base et en créant un profil. Cela nous aide à comprendre vos compétences, votre expérience et vos objectifs de carrière",
      candidatestep2Title : "Complétez votre profil ",
      candidatestep2 : "Complétez votre profil avec des informations sur vos qualifications, votre expérience professionnelle et vos préférences. Cela nous permet de vous proposer des opportunités pertinentes ",
      candidatestep3Title : "Parcourir les opportunités ",
      candidatestep3 : "Explorez les offres d'emploi ou les projets qui correspondent à vos compétences et à vos intérêts ",
      candidatestep4Title : "Soumettre une candidature ",
      candidatestep4 : "Postulez aux postes ou aux projets qui vous intéressent. Adaptez votre candidature pour mettre en évidence la manière dont votre expérience correspond au poste ",
      candidatestep5Title : "Get matched ",
      candidatestep5 : "Notre système examine votre profil et vos candidatures, puis vous met en relation avec les opportunités les plus appropriées ",
      candidatestep6Title : "Play coding games ",
      candidatestep6: "Améliorez vos compétences en codage grâce à des jeux amusants ",
      recruiterGuide : "Comment ça marche pour les recruteurs ",
      recruiterGuideDesc : "Comment le processus fonctionne pour les recruteurs ",
      recruitertep1Title : "Créer un compte ",
      recruitertep1 : "Inscrivez-vous en fournissant vos informations de base et en créant un profil. Cela nous permet de mieux comprendre vos besoins et de vous mettre en relation de manière efficace et efficiente.",
      recruitertep2Title : " Créer une description de poste détaillée ",
      recruitertep2 : " Veiller à ce que les descriptions d'emploi soient précises et comprennent les principales responsabilités, les qualifications requises et les compétences souhaitées ",
      recruititertep3Title : " Établir des critères d'évaluation ",
      recruitertep3 : " Élaborer des critères clairs pour l'évaluation des candidats (techniques, psychotechniques et de personnalité) afin de garantir la cohérence et l'équité du processus de sélection ",
      recruititertep4Title : " Rationaliser le processus de candidature ",
      recruitertep4 : " Surveiller et améliorer les performances des employés en utilisant des données ",
      recruitertep5Title : " Améliorer l'expérience des candidats ",
      recruitertep5 : " Trouvez et embauchez des candidats talentueux pour vos projets ",

      checkOur : " Vérifier notre ",
      latestJob : " Dernières offres d'emploi en cours ",
      descCheck : " Partagez votre projet avec nous, et nous vous mettrons rapidement en contact avec les freelances parfaits ",
      HappyCandidates : " Candidats heureux ",
      HappyCandidatesDesc : " Parlez-nous de votre projet et nous vous mettrons rapidement en relation avec les freelances idéaux ",
      FastTrack : " Accélérez votre réussite et obtenez des conseils rapides sur votre carrière ",
      FastTrackDesc : " Soumettez-nous les détails de votre projet et nous trouverons rapidement les freelances qu'il vous faut ",
      hirecueFooter : " Il est bien connu que l'impression d'un lecteur est grandement influencée par la mise en page d'une page ",
      Pricing:" Tarification ",
      AboutUs :" A propos de nous ",
      SignIn:"Se connecter",
      SignUp:"S'inscrire",
      email : " Email ",
      phone : " Téléphone ",
      type : " Type ",
      Customdemo : " Démonstration personnalisée ",
      Generaldemo : " Démonstration générale ",
      description : " Description ",
      close : " Fermer ",
      submit : " Envoyer ",
    }
  }
};
export default translations;
