import React from "react";
import Section from "../whyjobvia/section";
import WhyjobviaPage from "../whyjobvia/whyjobviaPage";
const whyjobvia = () => {
  document.title = "Team | HireCue - Job Listing Template | Pichforest";
  return (
    <React.Fragment>
      <Section />
      <WhyjobviaPage/>
    </React.Fragment>
  );
};

export default whyjobvia;