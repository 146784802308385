// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom select */
.custom-select {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none; 
  }
  
  /* Arrow */
  .custom-select::-ms-expand { 
    display: none; 
  }
  
  .custom-select::-ms-value {
    color: #495057;
    background-color: #fff;
  }`, "",{"version":3,"sources":["webpack://./src/pages/RequestDemo/demo.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,cAAc;IACd,WAAW;IACX,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;IACd,sBAAsB;IACtB,4BAA4B;IAC5B,sBAAsB;IACtB,sEAAsE;IACtE,gBAAgB;EAClB;;EAEA,UAAU;EACV;IACE,aAAa;EACf;;EAEA;IACE,cAAc;IACd,sBAAsB;EACxB","sourcesContent":["/* Custom select */\n.custom-select {\n    display: block;\n    width: 100%;\n    padding: 0.375rem 0.75rem;\n    font-size: 1rem;\n    font-weight: 400;\n    line-height: 1.5;\n    color: #212529;\n    background-color: #fff;\n    background-clip: padding-box;\n    border-radius: 0.25rem;\n    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;\n    appearance: none; \n  }\n  \n  /* Arrow */\n  .custom-select::-ms-expand { \n    display: none; \n  }\n  \n  .custom-select::-ms-value {\n    color: #495057;\n    background-color: #fff;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
