import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";


const whyjobviaPage = () => {
  const teamPage = [
    {
      id: 1,
     
      teamMemberName: "Rebecca Swartz",
      teamMemberPosition: "Founder & CEO"
    },
    {
      id: 2,

      teamMemberName: "James Lemire",
      teamMemberPosition: "Project Manager"
    },
    {
      id: 3,
  
      teamMemberName: "Charles Dickens",
      teamMemberPosition: "Financial Analyst"
    },
    {
      id: 4,
    
      teamMemberName: "Jeffrey Montgomery",
      teamMemberPosition: "UI/UX Designer"
    },
    {
      id: 5,
      teamMemberName: "Brooke Hayes",
      teamMemberPosition: "Team Leader"
    },
    {
      id: 6,
      teamMemberName: "Olivia Murphy",
      teamMemberPosition: "Designer"
    },
    {
      id: 7,
      teamMemberName: "Betty Richards",
      teamMemberPosition: "Developer"
    },
    {
      id: 8,
      teamMemberName: "Gabriel Palmer",
      teamMemberPosition: "Back End Developer"
    },
    {
      id: 9,
      teamMemberName: "Gabriel Palmer",
      teamMemberPosition: "Back End Developer"
    }
  ];
  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row>
            {teamPage.map((teamPageDetails, key) => (
              <Col lg={4} md={6} key={key}>
                <div className="team-box card border-0 mt-4">
                  <div className="team-img position-relative mx-auto">
                    <img
                      src={teamPageDetails.teamMemberImage}
                      alt=""
                      className="img-thumbnail"
                    />
                    <ul className="team-social list-unstyled">
                      <li>
                        <Link to="#">
                          <i className="mdi mdi-facebook"></i>
                        </Link>
                      </li>
                      <li className="my-1">
                        <Link to="#">
                          <i className="mdi mdi-twitter"></i>
                        </Link>{" "}
                      </li>
                      <li>
                        <Link to="#">
                          <i className="mdi mdi-skype"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="team-content card-body text-center">
                    <Link to="#" className="primary-link">
                      <h6 className="fs-17 mb-1">
                        {teamPageDetails.teamMemberName}
                      </h6>
                    </Link>
                    <p className="text-muted mb-0">
                      {teamPageDetails.teamMemberPosition}
                    </p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default whyjobviaPage;