import React, { useState ,useEffect} from 'react';
import axios from 'axios';

import { Icon } from 'react-icons-kit';
import { ic_keyboard_arrow_down } from 'react-icons-kit/md/ic_keyboard_arrow_down';


const FeatureTable = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [subscriptionFeature, setSubscriptionFeature] = useState([]);
  const [subscriptionfeaturespertypes, setSubscriptionfeaturespertypes] = useState([]);
  const [subscriptionType, setSubscriptionType] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionFeature/getByCategories`);
        console.log("features: ",response.data)
        setSubscriptionFeature(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionType/getAll`);
        console.log("sub: ", response.data)
        setSubscriptionType(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionfeaturespertypes/findAllWithTypes`);
        console.log("featurespertypes2: ", response.data)
        setSubscriptionfeaturespertypes(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  const features = {
    "Functionality to hire developers": [
      {
        name: 'Candidate Profiles',
        starter: 'yes',
        professional: 'yes', 
        enterprise: 'yes'
      },
      {
        name: 'Resume Parsing',
        starter: 'no',
        professional: 'yes',
        enterprise: 'yes'
      }
    ],

    "Assessments & tests": [
      {
        name: 'Skills Testing',
        starter: 'no',
        professional: 'yes',
        enterprise: 'yes'  
      },
      {
        name: 'Custom Assessments',
        starter: 'no',
        professional: 'no',
        enterprise: 'yes'
      }
    ],

    "Reporting & analytics": [
      {
        name: 'Analytics Dashboard',
        starter: 'no',
        professional: 'yes',
        enterprise: 'yes'
      },
      {
        name: 'Advanced Reporting',
        starter: 'no', 
        professional: 'no',
        enterprise: 'yes'
      }  
    ],

    "Integrations": [
      {
        name: 'ATS Integrations',
        starter: 'no',
        professional: 'no',
        enterprise: 'yes' 
      }
    ],

    "Quality control": [
      {
        name: 'Unlimited API Access', 
        starter: 'yes',
        professional: 'yes',
        enterprise: 'yes'
      }
    ],

    "Customer support": [
      {
        name: '24/7 Support',
        starter: 'no',
        professional: 'yes',
        enterprise: 'yes'
      }
    ],

    "Anti-cheating measures": [
      {
        name: 'Proctoring', 
        starter: 'no',
        professional: 'yes',
        enterprise: 'yes' 
      }
    ]
    
    };

  return (
    <div className="feature-section" style={{background: 'white'}}>
    
      <div className="toggle-features" onClick={() => setIsOpen(!isOpen)}>
        <Icon icon={ic_keyboard_arrow_down} /> Features
      </div>
      <br />
      {isOpen && 
        <div className="feature-table-wrapper">
          <div className="feature-table">
          <table>

<thead>

  <tr>

    <th></th>
    {subscriptionType.map((subscription, index) => (
      <>
        <th className="plan-header" key={subscription.id}>

        {subscription.Type}
<br/>

<span className="plan-availability">Available</span>

</th></>))}

  </tr>

</thead>
{Object.keys(subscriptionFeature).length > 0 && (
  <tbody>
    {Object.keys(subscriptionFeature).map(category => (
      <React.Fragment key={category}>
        <tr style={{background: '#ddd'}}>
          <td colSpan={subscriptionType.length+1}><b>{category}</b></td>
        </tr>
        {subscriptionFeature[category].map(feature => (
          <tr key={feature.name}>
            <td className="feature-name">{feature.name}</td>
            {subscriptionType.map((subscription, index) => (
              <td key={subscription.id}>
                {subscriptionfeaturespertypes.map((subscriptionTF, index) => {
                  if (subscriptionTF.feature_id === feature.id && subscription.id === subscriptionTF.type_id) {
                    return (
                      <>
                        <i className="fa fa-check"></i>
                        <br/>
                        <span className="feature-availability">{subscriptionTF.feature_available === 1 ? 'Yes' : '__'}</span>
                      </>
                    );
                  } else {
                    return null;
                  }
                })}
              </td>
            ))}
          </tr>
        ))}
      </React.Fragment>
    ))}
  </tbody>
)}


</table>
          </div>
        </div>
      }

      <style jsx>{`
      
        .toggle-features {
          background: #02af744f;
          padding: 10px;
          cursor: pointer;
          width: 100%;
          text-align: center;
        }
        
        .feature-table-wrapper {
          background: #f1f3f9;
        }
        
        .feature-table {
            border-collapse: collapse;
            width: 100%;
            background: #fff;
            max-width: 1200px;
            margin: 0 auto;  box-shadow: 0 0 10px #f1f3f9;
        }
        
        table {
          border-collapse: collapse; 
          width: 100%;
        }
        .feature-table th {
        background: #02af74;
        color: #fff;
        }
        .feature-table th, 
        .feature-table td {
        padding: 12px 15px;
        text-align: left; 
        border-bottom: 1px solid #ddd;
        }
        
        // Responsive
        @media (max-width: 768px) {

        .feature-table thead {
        display: none;
        }

        .feature-table tr {
        display: block;
        }

        .feature-table td {
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;
        }

        .feature-table td::before {
        content: attr(data-label);
        font-weight: bold;
        }

                }
                
        .fa-minus {
            color: #ccc;
        }
      `}</style>

    </div>
  );
}

export default FeatureTable;