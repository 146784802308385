// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add this CSS for the custom toggle switch */
.lunar-solar-switch {
    position: relative;
    display: inline-block;
    width: 150px;
    height: 40px;
    background-color: #ccefe3;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .lunar-solar-switch::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 75px;
    height: 36px;
    background-color: #ffffff;
    border-radius: 18px;
    transition: left 0.3s ease;
  }
  
  .lunar-solar-switch.active {
    background-color: #02af74;
  }
  
  .lunar-solar-switch.active::before {
    left: 75px;
  }
  
  /* Add this CSS to style the labels */
  .lunar-solar-label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
    right: 5px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #333;
  }
  .text-left {
    text-align: left !important;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Company/Pricing/pricing.css"],"names":[],"mappings":"AAAA,8CAA8C;AAC9C;IACI,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,0BAA0B;EAC5B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,UAAU;EACZ;;EAEA,qCAAqC;EACrC;IACE,kBAAkB;IAClB,QAAQ;IACR,2BAA2B;IAC3B,SAAS;IACT,UAAU;IACV,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;EACb;EACA;IACE,2BAA2B;EAC7B","sourcesContent":["/* Add this CSS for the custom toggle switch */\n.lunar-solar-switch {\n    position: relative;\n    display: inline-block;\n    width: 150px;\n    height: 40px;\n    background-color: #ccefe3;\n    border-radius: 20px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n  \n  .lunar-solar-switch::before {\n    content: \"\";\n    position: absolute;\n    top: 2px;\n    left: 2px;\n    width: 75px;\n    height: 36px;\n    background-color: #ffffff;\n    border-radius: 18px;\n    transition: left 0.3s ease;\n  }\n  \n  .lunar-solar-switch.active {\n    background-color: #02af74;\n  }\n  \n  .lunar-solar-switch.active::before {\n    left: 75px;\n  }\n  \n  /* Add this CSS to style the labels */\n  .lunar-solar-label {\n    position: absolute;\n    top: 50%;\n    transform: translateY(-50%);\n    left: 5px;\n    right: 5px;\n    font-size: 16px;\n    font-weight: bold;\n    text-align: center;\n    color: #333;\n  }\n  .text-left {\n    text-align: left !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
