import React from "react"; 
import { Container, Row, Col } from "reactstrap"; 
const Section = () => { 
  return ( 
  <React.Fragment> 
    <section className="bg-home3" id="home"> 
    <Container> <Row className="align-items-center"> 
    <Col lg={7}> <div className="mb-4 pb-3 me-lg-5"> 
    <h6 className="sub-title">Request a Demo</h6>
     <h1 className="display-5 fw-semibold mb-3"> See Our Platform in Action </h1>
      <p className="fs-18 text-muted mb-0"> Sign up for a free 30-minute live demo and get your questions answered by our team. </p>
       </div> 
       </Col>
        </Row> 
        </Container> 
        </section>
         </React.Fragment> 
         ); 
        }; 
export default Section;