import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import demo_image from "../../assets/images/demo_image.png";

import "./demo.css"

const RequestDemoPage = () => {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    const fetchCountries = async () => {
      const response = await fetch('https://restcountries.com/v2/all');
      const countries = await response.json();
      setCountries(countries);
    };

    fetchCountries();
  }, []);
  
  return (
    <section className="bg-light py-5">
      <Container>
        <Row className="align-items-center">
          <Col lg={5} md={6}>
            <img 
              src={demo_image} alt="demo_image"
              className="img-fluid"
            />
          </Col>

          <Col lg={7} md={6}>
            {/* <h2 className="mb-4">Sign up for a free 30-minute live demo</h2> */}
            <Form>

            <FormGroup>
              <Label>Work Email Address*</Label>  
              <Input type="email" />
            </FormGroup>

            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>First Name*</Label>
                  <Input type="text"/>
                </FormGroup>
              </Col>
              
              <Col md={6}>
                <FormGroup>
                  <Label>Last Name*</Label>
                  <Input type="text"/>
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label>Company Name*</Label>
              <Input type="text" />
            </FormGroup>

            <FormGroup>

              <Label>Job Function*</Label>

              <Input type="text" />

            </FormGroup>

            <FormGroup>
              <Label>Phone Number</Label>
              <Input type="tel" /> 
            </FormGroup>

            <FormGroup>
              <Label>How many people work there?*</Label>
              <Input type="number" />
            </FormGroup>

            <FormGroup>
        <Label>Country</Label>
        
        <Input 
        className="custom-select"
          type="select"
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}  
        >
          <option value="">Select Country</option>
            
          {countries.map(country => (
            <option 
              key={country.alpha2Code}
              value={country.name}
            >
              {country.name}
            </option>
          ))}
          
        </Input>
        
      </FormGroup>

            <FormGroup>
              <Label>How can we help?*</Label>
              <Input type="textarea" />
            </FormGroup>

              <Button color="primary">Submit</Button>

            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default RequestDemoPage;