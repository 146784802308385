import React from "react";
import Section from "../../Company/Pricing/Section";
import PricingPage from "../../Company/Pricing/PricingPage";
import Cta from "../../Company/Pricing/Cta";
import FeatureTable from "../../Company/Pricing/FeatureTable";

const Pricing = () => {
  document.title = "Pricing | HireCue - Job Listing ";
  return (
    <React.Fragment>
      <Section />
      <PricingPage/>
      <FeatureTable/>   
      {/* <Cta /> */}
      <br/>
    </React.Fragment>
  );
};

export default Pricing;
