import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import "./pricing.css";
import axios from 'axios';
import './App.css';

const EXCHANGE_RATES = {
  EUR: 1.00,
  USD: 1.09,
  TND: 2.00,
  GBP: 0.85
};


const SuccessDisplay = ({ sessionId }) => {
  return (
    <section>
      <div className="product Box-root">
        <div className="description Box-root">
          <h3>Subscription to starter plan successful!</h3>
        </div>
      </div>
      <form action="/create-portal-session" method="POST">
        <input
          type="hidden"
          id="session-id"
          name="session_id"
          value={sessionId}
        />
        <button id="checkout-and-portal-button"  className="btn-soft-primary" type="submit">
          Manage your billing information
        </button>
      </form>
    </section>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);



const PricingPage = () => {
  const [selectedCurrency, setSelectedCurrency] = useState("EUR");
  const [selectedBilling, setSelectedBilling] = useState("monthly");
  const [subscriptionType, setSubscriptionType] = useState([]);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [subscriptionfeaturespertypes, setSubscriptionfeaturespertypes] = useState([]);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionType/getAll`);
        console.log("sub: ", response.data)
        setSubscriptionType(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionfeaturespertypes/findAllWithTypes`);
        console.log("featurespertypes: ", response.data)
        setSubscriptionfeaturespertypes(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  /* if (!success && message === '') {
    return <FreeDisplay />;
  } else if (success && sessionId !== '') {
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  } */

  const calculateConvertedPrice = (price, currency) => {
    const rate = selectedBilling === "monthly" ? 1 : 12;
    return (parseFloat(price.slice(1)) * EXCHANGE_RATES[currency] * rate).toFixed(2);
  };
  const subscriptionLinks = {
    Free: "https://app.hirecue.com/page-register",
    "Solo Starter": "https://app.hirecue.com/page-register",
    "Solo Pro": "https://app.hirecue.com/page-register",
    "Solo Premium": "https://app.hirecue.com/page-register",
    "Entreprise Starter": "https://app.hirecue.com/page-register",
    "Entreprise Pro": "https://app.hirecue.com/page-register",
    "Entreprise Premium": "https://app.hirecue.com/page-register",
    "Pay as you go": "https://app.hirecue.com/page-register"
  };
  
  const PurchaseLink = ({ subscriptionType }) => {
    console.log("    subscriptionType    :",subscriptionType)
    const link = subscriptionLinks[subscriptionType];
    console.log("    link    :",link)

    if (!link) return null;
  
    return (
      <a href={link} target="_blank" rel="noopener noreferrer" className="btn-soft-primary rounded-pill">
      Purchase Now <i className="uil uil-arrow-right"></i>
    </a>
    );
  };
  
  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="text-center">
                <h1 className="badge bg-warning-subtle text-warning  fs-15 mb-2">
                  Choose Your Plan
                </h1>
                <p className="text-muted">
                  The faster, most seamless CI & development you'll find
                  anywhere.
                </p>
              </div>
            </Col>
          </Row>
          <div className="text-center mt-3">
            <button
              className={`btn btn-outline-secondary me-2 ${
                selectedCurrency === "EUR" ? "active" : ""
              }`}
              onClick={() => setSelectedCurrency("EUR")}
            >
              EUR
            </button>
            <button
              className={`btn btn-outline-secondary me-2 ${
                selectedCurrency === "USD" ? "active" : ""
              }`}
              onClick={() => setSelectedCurrency("USD")}
            >
              USD
            </button>
            <button
              className={`btn btn-outline-secondary me-2 ${
                selectedCurrency === "TND" ? "active" : ""
              }`}
              onClick={() => setSelectedCurrency("TND")}
            >
              TND
            </button>
            <button
              className={`btn btn-outline-secondary ${
                selectedCurrency === "GBP" ? "active" : ""
              }`}
              onClick={() => setSelectedCurrency("GBP")}
            >
              GBP
            </button>
          </div>
          <div className="text-center mt-3">
            <label className={`lunar-solar-switch ${selectedBilling === "annual" ? "active" : ""}`}>
              <input
                type="checkbox"
                className="visually-hidden"
                checked={selectedBilling === "annual"}
                onChange={() => setSelectedBilling(selectedBilling === "monthly" ? "annual" : "monthly")}
              />
              <span className="lunar-solar-label">
                {selectedBilling === "monthly" ? "Monthly" : "Annually"}
              </span>
            </label>
          </div>
          <br></br>
          <Row className="pricing-grid">
  {subscriptionType.map((subscription, index) => (
    <Col lg={4} md={6} className="mt-5 pt-2" key={subscription.id}>
      <Card className="pricing-box bg-light h-100">
        <CardBody className="p-4 px-lg-5">
        <div className="pricing-icon bg-light rounded-circle icons-md">
          <Icon icon="uim-telegram-alt" className="text-primary" />
        </div>
        <div className="pricing-name text-center mt-4 pt-2">
          <h4 className="fs-18">{subscription.Type}</h4>
        </div>
        <div className="pricing-price text-center mt-4">
          <h2 className="fw-semibold">
            {calculateConvertedPrice(subscription.MonthlyPrice, selectedCurrency)}
            <small className="fs-16">{selectedCurrency} {selectedBilling === "monthly" ? "mo" : "yr"}</small>
          </h2>
        </div>
        <ul className="list-unstyled pricing-details text-muted mt-4 text-left">
          {subscriptionfeaturespertypes
            .filter(feature => feature.type_id === subscription.id && feature.feature_available ===1 )
            .map(feature => (
              <li key={feature.feature_id} >
                <i className="mdi mdi-check-bold bg-success-subtle text-success me-2"></i>
                {feature.feature_name} : {feature.feature_quota_max}
              </li>
            ))}
        </ul>
        <div className="text-center mt-4 mb-2">
    {/* Bouton "Purchase Now" */}
              <PurchaseLink subscriptionType={subscription.Type} />

  </div>
</CardBody>
    </Card>
  </Col>
))}

          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default PricingPage;
