import React , { useEffect, useState }from "react";
import { Col, Container, Row, Form } from "reactstrap";
import SwiperCore, { Autoplay } from "swiper";
import translations from "../../components/mulitlingue";

const Section = () => {
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  const [selectedLang, setSelectedLang] = useState(lang);
  SwiperCore.use([Autoplay]);
  return (
    <React.Fragment>
      <section className="bg-home3" id="home">
        <Container>
          <Row className="align-items-center">
            <Col lg={7}>
              <div className="mb-4 pb-3 me-lg-5">
              <h6 className="sub-title">{lang.menu.wehave}  <span className="text-warning fw-bold"> 150,000+ </span>  {lang.menu.liveJobs}</h6>
           <h1 className="display-3 fw-bolder mb-3">
           {lang.menu.pageTitle} </h1>
                <p className="fs-18 pbanner1 mb-0">
                {lang.menu.pageDescr}                </p>
    <p>{lang.menu.livejobs2} </p>
              
              </div>
              <Form action="#">
                <div className="registration-form">
              <Row className="g-0">
                  </Row>
                </div>
              </Form>
            </Col>

            <Col lg={5}>
              <div className="mt-5 mt-lg-0 ms-xl-5">
                <div className="quote-icon">
                  <i className="mdi mdi-format-quote-open icon"></i>
                  <i className="mdi mdi-format-quote-open icon-2 text-primary"></i>
                </div>
                
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Section;
